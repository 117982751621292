//this mix file to change status for any doc in the system
import ApiService from "@/core/services/api.service";
import Vue from "vue";

let changeStatusToConfirm = (url, id, status, params = {})=>{
    ApiService.patch(url + '/' + id, {
        status: status,
        ...params
    }).then((response) => {
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
    }).catch(error => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
    });
}

export default {changeStatusToConfirm}